import { createGlobalStyle } from 'styled-components';
import { viewMax, viewSd } from './actions/variables';
import loadingImage from './assets/images/loading.gif';

require('typeface-roboto');

const StylesGlobal = createGlobalStyle`

    :root{
        --color-base: #2ea3f2;
        --color-base-light: #d6eeff;
        --color-grey: #e2e3e5;
        --color-base-dark: #066791;
        --color-lime: #a9c028;
        --color-lime-dark: #9eb60a;
        --color-logo: #a2b527;
        --text-color-grey: #3e3e3e;

        --width-max: 1400px;
        --width-hd: 1200px;
    }

    *{
        box-sizing: border-box;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
    }

    body{
        background-color: white;
        padding: 0px;
        margin: 0px;
        .contentWidth{
            width: var(--width-max);
            margin: 0px auto;
            display: flex;
            flex-direction: column;
            @media (max-width: ${viewMax}px){
                width: 100%;
            }
        }
        .loadingLayer{
            position: relative;
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 300;
                background-color: rgba(255,255,255,0.6);
            }
            &:before{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -8px;
                margin-top: -8px;
                width: 16px;
                height: 16px;
                z-index: 301;
                background-image: url(${loadingImage});
            }
        }
        @media (max-width: ${viewSd}px){
            .layerTable{
                width: 100%;
                overflow-x: auto;
                table{
                    max-width: 600px !important;
                }
            }
        }
    }
`;	

export default StylesGlobal;