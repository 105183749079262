import React, { useEffect, useState } from 'react';
import CookiesStyle from './CookiesStyle';
import { readCookie, createCookie } from '../../actions/functions';

const Cookies = () => {

    const [acceptCookie,setAcceptCookie] = useState(true);

    useEffect(() => {
        if (!readCookie('acceptCookie')) setAcceptCookie(false);
    },[]);

    const handleAccept = event => {
        event.preventDefault();
        createCookie('acceptCookie');
        setAcceptCookie(true);
    }

    if (acceptCookie) return null;

    return (
        <CookiesStyle>
            W związku ze zmianą polityką prywatności, zapraszamy do zapoznania się ze zmianami na naszej stronie
            <div className="buttons">
                <a href="" className="accept" onClick={handleAccept}>
                    Ok, rozumiem,
                </a>
                <a href="/privacy" className="readMore">
                    czytaj więcej ...
                </a>
            </div>
        </CookiesStyle>
    );
}
export default Cookies;