import React from 'react';
import { Helmet } from "react-helmet"

const SeoMeta = () => {

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Skup tonerów Warszawa centrum: pełnych niewykorzystanych, oryginalnych, pustych, zużytych - SprzedajToner.pl</title>
            <meta name="description" content="Centrum skupu pełnych, niewykorzystanych tonerów na terenie całej Warszawy oraz Polski. EKSPRESOWO przygotujemy dla Ciebie wycenę. Sprawdź, jakie to proste." />
            <meta name="keywords" content="tonery,skup,sprzedajtoner,samsung,cacon" />
            <meta 
                name='viewport'
                content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
            />
        </Helmet>
    );
}

export default SeoMeta;