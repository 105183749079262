import React from 'react';
import MapStyle from './MapStyle';
import logo from '../../assets/images/sprzedaj-toner-logo.png';
import pinLogo from '../../assets/images/pin-logo.png';
import pdf from '../../assets/pdf/ceidg_trio_color.pdf';
import GoogleMapReact from 'google-map-react';
import style from './style';

const mapKey = 'AIzaSyBdtcAwmmZ8qiCNUorcqIE1WWhIlKPr_mw';
const center = {
    lat: 52.207603,
    lng: 20.890594
}
const zoom = 11;

const MarkerStyled = ({ text }) => <div className="marker"><div className="imageLogo"><img src={pinLogo} alt="Sprzedaj toner" /></div>{text}</div>;

const Map = ({ refContact }) => {

    return (
        <MapStyle>
            <div className="contact" ref={refContact}>
                <div className="content">
                    <div className="logo">
                        <img src={logo} alt="Sprzedaj toner" />
                    </div>
                    <div className="contactData">
                        <div className="data">
                            <header>Dział handlowy i magazyn</header>
                            <p>
                                ul. Pola Karolińskie 4 lok. 2<br />
                                02-401 Warszawa<br />
                                tel.: 602-594-898<br />
                                e-mail: <a href="mailto:info@sprzedajtoner.pl">info@sprzedajtoner.pl</a>
                            </p>
                        </div>
                        <div className="data">
                            <header>Obsługa w siedzibie</header>
                            <p>
                                Poniedziałek - piątek: 08:30 - 17:30<br />
                                Kontakt telefoniczny: 24h/7/365<br />
                                NIP: 5252206809<br />
                                <a href={pdf}>Nasz wpis w CEIDG - plik PDF</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: mapKey }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={{
                        styles: style.mapStyle
                    }}
                    >

                    <MarkerStyled
                        lat={center.lat}
                        lng={center.lng}
                    />


                </GoogleMapReact>
            </div>
        </MapStyle>
    );
}
export default Map;