export const emailContact = 'info@sprzedajtoner.pl';
export const phone = '602594898';
export const viewMax = 1500;
export const viewHd = 1366;
export const viewHdSmall = 1000;
export const viewSd = 768;
export const viewMobile = 550;

export const defaultCountry = 'Polska';

export const countrys = [
        { prefixPhone: '48', prefix: ['PL'], name: 'Polska', mask: "+99-999 999 999" },
        { prefixPhone: '43', prefix: ['AT'], name: 'Austria', mask: "+99-999 999 999" },
        { prefixPhone: '37', prefix: ['BLR'], name: 'Białoruś', mask: "+99-999 999 999" },
        { prefixPhone: '32', prefix: ['BE'], name: 'Belgia', mask: "+99-999 999 999" },
        { prefixPhone: '41', prefix: ['CH'], name: 'Szwajcaria', mask: "+99-999 999 999" },
        { prefixPhone: '357', prefix: ['CY'], name: 'Cypr', mask: "+999-999 999 999" },
        { prefixPhone: '420', prefix: ['CZ'], name: 'Czechy', mask: "+999-999 999 999" },
        { prefixPhone: '49', prefix: ['DE'], name: 'Niemcy', mask: "+99-999 999 999" },
        { prefixPhone: '45', prefix: ['DK'], name: 'Dania', mask: "+99-999 999 999" },
        { prefixPhone: '372', prefix: ['EE'], name: 'Estonia', mask: "+999-999 999 999" },
        { prefixPhone: '30', prefix: ['EL'], name: 'Grecja', mask: "+99-999 999 999" },
        { prefixPhone: '34', prefix: ['ES'], name: 'Hiszpania', mask: "+99-999 999 999" },
        { prefixPhone: '358', prefix: ['FI'], name: 'Finlandia', mask: "+999-999 999 999" },
        { prefixPhone: '33', prefix: ['FR'], name: 'Francja', mask: "+99-999 999 999" },
        { prefixPhone: '385', prefix: ['HR'], name: 'Chorwacja', mask: "+999-999 999 999" },
        { prefixPhone: '36', prefix: ['HU'], name: 'Węgry', mask: "+99-999 999 999" },
        { prefixPhone: '353', prefix: ['IE'], name: 'Irlandia', mask: "+999-999 999 999" },
        { prefixPhone: '354', prefix: ['IS'], name: 'Islandia', mask: "+999-999 999 999" },
        { prefixPhone: '39', prefix: ['IT'], name: 'Włochy', mask: "+99-999 999 999" },
        { prefixPhone: '423', prefix: ['LI'], name: 'Liechtenstein', mask: "+999-999 999 999" },
        { prefixPhone: '370', prefix: ['LT'], name: 'Litwa', mask: "+990-999 999 999" },
        { prefixPhone: '352', prefix: ['LU'], name: 'Luksemburg', mask: "+999-999 999 999" },
        { prefixPhone: '371', prefix: ['LV'], name: 'Łotwa', mask: "+999-999 999 999" },
        { prefixPhone: '356', prefix: ['MT'], name: 'Malta', mask: "+999-999 999 999" },
        { prefixPhone: '31', prefix: ['NL'], name: 'Holandia', mask: "+99-999 999 999" },
        { prefixPhone: '47', prefix: ['NO'], name: 'Norwegia', mask: "+99-999 999 999" },
        { prefixPhone: '351', prefix: ['PT'], name: 'Portugalia', mask: "+999-999 999 999" },
        { prefixPhone: '40', prefix: ['RO'], name: 'Rumunia', mask: "+99-999 999 999" },
        { prefixPhone: '46', prefix: ['SE','SV'], name: 'Szwecja', mask: "+99-999 999 999" },
        { prefixPhone: '386', prefix: ['SI','SL'], name: 'Słowenia', mask: "+999-999 999 999" },
        { prefixPhone: '421', prefix: ['SK'], name: 'Słowacja', mask: "+999-999 999 999" },
        { prefixPhone: '44', prefix: ['UK','EN'], name: 'Wielka Brytania', mask: "+99-999 999 999" }, 
]