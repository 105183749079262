import styled from 'styled-components';

const CookiesStyle = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 35px 15px;
    color: #cecece;
    background-color: rgba(0,0,0,0.9);
    z-index: 1000;
    font-size: 14px;
    text-align: center;
    .buttons{
        margin-top: 15px;
        a{
            color: white;
            text-decoration: none;
            display: inline-block;
            margin: 0px 5px;
            &.readMore{
                color: orange;
            }
            &:hover{
                color: grey;
                text-decoration: underline;
            }
        }
    }
`;	

export default CookiesStyle;