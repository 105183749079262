import styled from 'styled-components';
import { viewMax , viewHd , viewSd , viewMobile , viewHdSmall } from '../../actions/variables';

const heightTop = 47;

const HeaderStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    display: flex;
    flex-direction: column;

    .mobileContacts{
        position: fixed;
        bottom: -100%;
        left: 0px;
        width: 100%;
        z-index: 1000;
        background-color: white;
        transition: all 0.3s;
        &.show{
            bottom: 0px;
        }
        ul{
            display: block;
            list-style: none;
            margin: 0px;
            padding: 0px;
            li{
                display: block;
                margin: 0px;
                padding: 1px;
                background-color: #ececec;
                a{
                    display: block;
                    padding: 10px 20px 40px 20px;
                    background-color: #f5f5f5;
                    font-size: 18px;
                    color: black;
                    text-decoration: none;
                    position: relative;
                    svg{
                        position: relative;
                        top: 14px;
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        path{
                            fill: green;
                        }
                    }
                }
                &.email a svg path{
                    fill: black;
                }
                &.messanger a svg path{
                    fill: blue;
                }
            }
        }
    }

    .mobileContactsMask{
        position: fixed;
        top: -100%;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0,0,0,0.7);
        opacity: 0;
        transition: all 0.3s;
        &.show{
            top: 0px;
            opacity: 1;
            /* animation: hideOut 0.5s normal forwards ease-in-out; */
        }
    }
    
    .top{
        display: block;
        height: ${heightTop}px;
        background-color: var(--color-base);
        *{
            color: white;
        }
        .contentWidth{
            display: block;
            /* display: flex;
            flex-direction: row;
            justify-content: center; */
            /* align-items: center; */
            h1{
                display: block;
                margin: 0px;
                padding: 0px;
                font-size: 20px;
                margin: 12px 0px 0px 0px;
                font-weight: 700;
                font-size: 18px;
            }
            .right{
                display: flex;
                flex-direction: row;
                position: absolute;
                top: 0px;
                right: 0px;
                height: ${heightTop}px;
                ul.main{
                    /* align-self: center; */
                    display: flex;
                    flex-direction: row;
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                    justify-content: center;
                    align-items: center;
                    li{
                        display: block;
                        margin-right: 10px;
                        a{
                            font-weight: 700;
                            text-decoration: none;
                            height: ${heightTop}px;
                            &:hover{
                                text-decoration: underline;
                            }
                            svg{
                                position: relative;
                                top: 2px;
                                display: inline-block;
                                margin-right: 5px;
                                font-size: 14px;
                            }
                        }
                        &.userData{
                            a.login{
                                display: inline-block;
                                padding: 3px 5px 3px 8px;
                                &:hover{
                                    background-color: var(--color-base-dark);
                                }
                            }
                        }
                        &.socials{
                            margin-left: 20px;
                            a{
                                display: inline-block;
                                margin-left: 4px;
                                width: 25px;
                                height: 12px;
                                position: relative;

                                svg{
                                    display: block;
                                    font-size: 18px;
                                    position: absolute;
                                    top: -3px;
                                    left: 0px;
                                    margin: 0px;
                                    padding: 0px;
                                    &.google{
                                        font-size: 28px;
                                        top: -8px;
                                    }
                                }
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .userPlace{
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    display: block;
                    height: ${heightTop}px;
                    a.login{
                        height: inherit;
                        display: block;
                        text-decoration: none;
                        padding: 15px 20px 0px 13px;
                        position: relative;
                        &:before{
                            content: '';
                            width: 1px;
                            height: 20px;
                            position: absolute;
                            top: 15px;
                            left: 0px;
                            background-color: #9fd8ff;
                        }
                        span{
                            display: inline-block;
                            position: relative;
                            top: -1px;
                            margin-right: 5px;
                        }
                        &:hover{
                            background-color: var(--color-base-dark);
                        }
                    }
                }
            }   
        }
    }
    .bottom{
        height: 152px;
        display: flex;
        align-items: center;
        .contentWidth{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            a.logo{
                flex: 1;
                display: block;
                position: relative;
                img{
                    max-width: 100%;
                }
            }
            .hamburgerMenu{
                display: none;
            }
            ul{
                /* flex: 1; */
                display: block;
                margin: 0px;
                padding: 0px;
                list-style: none;
                position: relative;
                li{
                    display: inline-block;
                    padding: 0px;
                    margin: 0px;
                    a{
                        display: inline-block;
                        padding: 10px 15px;
                        color: black;
                        text-transform: uppercase;
                        text-decoration: none;
                        border-radius: 20px;
                        transition: all 0.3s;
                        font-weight: 500;
                        &:hover{
                            background-color: var(--color-base-light);
                        }
                        &.express{
                            background-color: #028ac4;
                            color: white;
                            &:hover{
                                background-color: black;
                            }
                        }
                        &.mainPage{
                            color: var(--color-base-dark);
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${viewMax}px){
        .top{
            .contentWidth{
                h1{
                    padding-left: 20px;
                }
            }
        }
        .bottom{
            .contentWidth{
                a.logo{
                    margin-left: 30px;
                }
                ul{
                    right: 30px;
                }
            }
        }
    }

    @media (max-width: ${viewHd}px){
        .bottom{
            .contentWidth{
                a.logo{
                    width: 230px;
                    img{
                        width: inherit;
                    }
                }
                ul{
                    li{
                        a{
                            padding-right: 13px;
                            padding-left: 13px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${viewHdSmall}px){
        .top{
            .contentWidth{
                h1{
                    display: none;
                }
                ul{
                    flex: 1;
                    margin-left: 30px;
                }
                .right{
                    .userPlace{
                        flex: none;
                    }
                }
            }
        }
        .bottom{
            height: 150px;
            display: block;
            .contentWidth{
                .hamburgerMenu{
                    display: block;
                    position: absolute;
                    top: 55px;
                    right: 40px;
                    color: var(--color-base-dark);
                    svg{
                        width: 40px;
                        height: 40px;
                    }
                }
                ul{
                    display: none;
                }
                display: block;
                a.logo{
                    width: 300px;
                    left: 15px;
                    position: absolute;
                    top: 25px;
                    left: 20px;
                }
            }
        }
    }

    @media (max-width: ${viewSd}px){
        .top{
            .contentWidth{
                h1{
                    display: none;
                }
            }
        }
    }

    @media (max-width: ${viewMobile}px){
        .top{
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100%;
            z-index: 800;
            .contentWidth{
                ul{
                    li.socials{
                        display: none;
                    }
                }
                .right{
                    flex: 1;
                    width: 100%;
                    ul.main{
                        flex: 1;
                        .mail{
                            display: none;
                        }
                    }
                }
            }
        }
        .bottom{
            height: 100px;
            display: block;
            .contentWidth{
                display: block;
                a.logo{
                    width: 200px;
                    left: 15px;
                    position: absolute;
                    top: 22px;
                    left: 20px;
                }
                .hamburgerMenu{
                    top: 32px;
                }
            }
        }
    }
    @media (max-height: 434px){
        .top{
            display: none;
        }
    }
`;	

export default HeaderStyle;