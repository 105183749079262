import React , { useState, useEffect } from 'react';
import LoginStyle from './LoginStyle';
import { useClickOutside } from 'react-click-outside-hook';
import api from '../../actions/api';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FaTimes } from 'react-icons/fa';
import { isEmpty } from '../../actions/functions';

const Login = ({ setShowLogin , setReadUserDataStorage , Notify }) => {

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [loading,setLoading] = useState(false);
    const [viewNr,setViewNr] = useState(0);// 0 - login, 1 - forget password, 2 - 
    const [header,setHeader] = useState('Logowanie');

    const [errEmail,setErrEmail] = useState(false);
    const [errPassword,setErrPassword] = useState(false);

    const [refOverlay, hasClickedOutsideOverlay] = useClickOutside();

    useEffect(() => {
        if (hasClickedOutsideOverlay){
            setShowLogin(false);
        }
    },[hasClickedOutsideOverlay]);

    useEffect(() => {
        switch (viewNr){
            case 0 : setHeader('Logowanie'); break;
            case 1 : setHeader('Zapomniałem hasła'); break;
        }

    },[viewNr]);

    const handleButtonClose = ev => {
        ev.preventDefault();
        setShowLogin(false);
    }

    const putLocalStorages = userData => {
        for(let i=1;i<=3;i++){
            const dataStorage = reactLocalStorage.getObject('data_form_'+i);
            if (!isEmpty(dataStorage)){
                dataStorage.name = userData.name;
                dataStorage.surname = userData.surname;
                dataStorage.city = userData.city;
                dataStorage.code = userData.code;
                dataStorage.street = userData.street;
                dataStorage.house = userData.street_number;
                dataStorage.country = userData.country;
                dataStorage.email = userData.email;
                dataStorage.phone = userData.phone;
                dataStorage.bank = userData.bank_account;
                dataStorage.firm = userData.firm_name;
                reactLocalStorage.setObject('data_form_'+i,dataStorage)
            }
        }
    }

    const handleButtonLogin = ev => {
        ev.preventDefault();
        setLoading(true);
        api('login','',{ email, password },r => {
            setLoading(false);
            if (r.result){
                // console.log(r);
                reactLocalStorage.setObject('userData',r.data.userData);
                reactLocalStorage.set('userToken',r.data.token);
                putLocalStorages(r.data.userData);
                setReadUserDataStorage(v => v+1);
                Notify('Logowanie','Zostałeś poprawnie zalogowany');
                setShowLogin(false);
            } else {
                Notify('Logowanie',r.comm,'error');
                if (r.errCode === 1 || r.errCode === 4 || r.errCode === 3){
                    setErrPassword(true);
                    setErrEmail(true);
                } else if (r.errCode === 2){
                    setErrPassword(true);
                }
            }
        });
    }

    const handleButtonForget = () => {
        setLoading(true);
        api('forgetPassword','',{ email },r => {
            setLoading(false);
            if (r.result){
                Notify('Zapomniałem hasła',r.data.comm);
                setShowLogin(false);
            } else {
                Notify('Zapomniałem hasła',r.comm,'error');
                setErrEmail(true);
            }
        });
    }

    const handleForgetClick = ev => {
        ev.preventDefault();
        setViewNr(1);
    }

    const handleLoginClick = ev => {
        ev.preventDefault();
        setViewNr(0);
    }

    return (
        <LoginStyle>
            <div className={"overlay"+(loading ? ' loadingLayer' : '')} ref={refOverlay}>
                <header>{header}</header>
                <div className="body">
                    {viewNr === 0 &&
                        <>
                            <div className={"row"+(errEmail ? ' error' : '')}>
                                <label>Podaj swój adres e-mail</label>
                                <input value={email} onChange={ev => setEmail(ev.target.value)} onFocus={() => setErrEmail(false)} />
                            </div>
                            <div className={"row"+(errPassword ? ' error' : '')}>
                                <label>Podaj hasło</label>
                                <input type="password" value={password} onChange={ev => setPassword(ev.target.value)} onFocus={() => setErrPassword(false)} />
                            </div>
                            <div className="row buttons">
                                <button onClick={handleButtonLogin}>Zaloguj</button>
                                <button onClick={handleButtonClose} className="close"><FaTimes /> Zamknij</button>
                                <a href="" onClick={handleForgetClick} className="forget">Zapomniałem hasła</a>
                            </div>
                        </>
                    }
                    {viewNr === 1 &&
                        <>
                            <div className={"row"+(errEmail ? ' error' : '')}>
                                <label>Podaj swój adres e-mail</label>
                                <input value={email} onChange={ev => setEmail(ev.target.value)} onFocus={() => setErrEmail(false)} />
                            </div>
                            <div className="content">
                                Na podany adres e-mail zostanie wysłany link, który umożliwi zmianę hasła 
                            </div>
                            <div className="row buttons">
                                <button onClick={handleButtonForget}>Wyślij</button>
                                <a href="" onClick={handleLoginClick} className="forget">Powrót</a>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        </LoginStyle>
    );
}
export default Login;