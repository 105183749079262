import styled from 'styled-components';
import { viewMobile } from '../../../actions/variables';

const UserStyle = styled.div`
    position: relative;
    padding: 0px;
    cursor: pointer;
    z-index: 1;
    .userName{
        position: relative;
        background-color: var(--color-base-dark);
        padding: 12px 25px 1px 25px;
        height: 47px;
        color: white;
        display: block;
        text-decoration: none;
        svg{
            position: relative;
            top: 2px;
            margin-right: 10px;
        }
    }
    ul{
        background-color: var(--color-base);
        display: none;
        list-style: none;
        padding: 0px;
        margin: 0px;
        li{
            padding: 0px;
            margin: 0px;
            a{
                display: block;
                padding: 10px 10px 10px 26px;
                text-align: left;
                font-weight: 300;
                text-decoration: none;
                position: relative;
                white-space: nowrap;
                svg{
                    position: relative;
                    top: 3px;
                    margin-right: 10px;
                }
                &:hover{
                    background-color: var(--color-base-dark);
                }
            }
        }
    }
    &:hover{
        ul{
            display: block;
        }
    }
    @media (max-width: ${viewMobile}px){
        ul{
            position: absolute;
            bottom: 47px;
            right: 0px;
        }
        .userName{
            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
`;	

export default UserStyle;